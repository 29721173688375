import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FAFAFA',
      neutralLight3: '#EFEFEF',
      neutralLight2: '#CBCBCC',
      neutralDark5: '#6E6E6E',
      neutralDark4: '#363636',
      neutralDark3: '#232B22',
      neutralDark2: '#1A1A1A',
      neutralDark1: '#000000',
      primaryDark: '#3A4638',
      primaryDark2: '#4E594C',
      primaryLight: '#F23030',
    },
  },
  fontFamily: {
    heading: "'beaufort-pro', serif",
    paragraph: "'acumin-pro', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }: any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
